import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { LOAN_TYPES } from '@common/constants/client'
import queryString from 'query-string'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

import { IClientInfo } from '@common/interfaces/client'
import ClientDashboardHeader from '../../components/ClientDashboard/ClientDashboardHeader'
import ClientAccountActivity from '../../components/Client/ClientAccountActivity'
import ClientAmendments from '../../components/Client/ClientAmendments'
import ClientTermLoans from '../../components/Client/ClientTermLoans'
import ClientFinancials from '../../components/Client/ClientFinancials'
import RiskRatingVisualization from '../../components/Client/RiskRatingVisualization'
import InventoryHealthVisualization from '../../components/Client/InventoryHealthVisualization'
import {
  ClientPageActivityOption,
  ClientPageTabNames,
  ClientPageTabSubNames,
  ROUTES,
} from '../../constants/routes'
import BBCARSummaryTable from '../../components/BBCARSummaryTable'
import BBCAPSummaryTable from '../../components/BBCAPSummaryTable'
import BBCInventoryDistributionTable from '../../components/BBCInventoryDistributionTable'
import SalesBySKUVisualizationTable from '../../components/SalesBySKUVisualizationTable'
import { BBC_TABS, WorkflowPage } from '@common/interfaces/bbc'
import LoanLedger from '../../components/Client/ClientLoanLedger'
import { CATEGORIES } from '@common/constants/tracking'
import useTrackVisualizationsTabSelection from '../../hooks/useTrackVisualizationsTabSelection'
import DilutionByCustomer from '../../components/DilutionByCustomer'
import { WorkflowTypes } from '@common/interfaces/notes'
import Card from '../../components/Common/Card'
import DilutionOverTime from '../../components/DilutionOverTime'
import BBCRollforwardTable from '../../components/BBCRollforwardTable'
import CapTableSummaryTable from '../../components/CapTableSummaryTable'
import BankTransactions from '../../components/BankTransactions'
import { usePermissions } from '../../helpers/permissionContext'
import TabsWithRoutesMenu from '../../components/Common/TabsWithRoutesMenu'
import DueDiligenceCompanyBackgroundPage from '../DueDiligenceCompanyBackgroundPage'
import DueDiligenceTeamPage from '../DueDiligenceTeamPage'

const TABS = Object.values(ClientPageTabNames).reduce((acc, tab) => {
  if (tab === ClientPageTabNames.Company) {
    acc[tab] = [
      `${ROUTES.CLIENT_PAGE}/?tab=${ClientPageTabSubNames.CapTable}`,
      `${ROUTES.CLIENT_PAGE}/?tab=${ClientPageTabSubNames.Background}`,
      `${ROUTES.CLIENT_PAGE}/?tab=${ClientPageTabSubNames.TheTeam}`,
    ]
  } else {
    acc[tab] = `${ROUTES.CLIENT_PAGE}/?tab=${tab}`
  }
  return acc
}, {})

const MENU = {
  [ClientPageTabNames.Company]: [
    {
      label: ClientPageTabSubNames.CapTable,
      route: `${ROUTES.CLIENT_PAGE}/?tab=${ClientPageTabSubNames.CapTable}`,
    },
    {
      label: ClientPageTabSubNames.Background,
      route: `${ROUTES.CLIENT_PAGE}/?tab=${ClientPageTabSubNames.Background}`,
    },
    {
      label: ClientPageTabSubNames.TheTeam,
      route: `${ROUTES.CLIENT_PAGE}/?tab=${ClientPageTabSubNames.TheTeam}`,
    },
  ],
}

interface IProps {
  clientInfo: IClientInfo
  isAdminRightsRole: boolean
  isExtendedRightsRole: boolean
  showClient: (id: string) => void
  hideClient: () => void
  hideBBC: () => void
  loginAsClient: (clientId: string) => void
  getDebtorIneligibleCategories: (data: object) => void
}

const ClientPage = ({
  clientInfo,
  showClient,
  hideClient,
  hideBBC,
  loginAsClient,
  isAdminRightsRole,
  getDebtorIneligibleCategories,
}: IProps) => {
  const { id: clientId } = useParams<{ id: string }>()
  const { isParticipant } = usePermissions()
  const history = useHistory()
  const { search }: { search: string } = useLocation()
  const { tab, scrollTo } = queryString.parse(search) as { tab: string; scrollTo: string }
  const currentTab = useMemo(() => tab || ClientPageTabNames.Overview, [tab])
  const [isInventoryDistributionInitialized, setIsInventoryDistributionInitialized] =
    useState(false)
  const [isLoadingClient, setIsLoadingClient] = useState(false)
  const [clientActivityOption, setClientActivityOption] = useState(
    ClientPageActivityOption.AccountActivity,
  )

  const handleLoadClient = useCallback(async () => {
    if (clientId) {
      setIsLoadingClient(true)
      hideClient()
      await showClient(clientId)
      setIsLoadingClient(false)
    }
  }, [clientId, showClient, hideClient])

  useEffect(() => {
    handleLoadClient()
  }, [handleLoadClient])

  const tabs = useMemo(() => {
    return Object.keys(TABS).reduce((acc, key) => {
      if (
        isParticipant &&
        [
          ClientPageTabNames.Risk,
          ClientPageTabNames.BankTransactions,
          ClientPageTabNames.Company,
        ].includes(key as ClientPageTabNames)
      ) {
      } else if (clientInfo?.loanType === LOAN_TYPES.ar && key === ClientPageTabNames.Inventory) {
      } else if (
        clientInfo?.loanType === LOAN_TYPES.inventory &&
        key === ClientPageTabNames.Receivables
      ) {
      } else {
        acc[key] = TABS[key]
      }
      return acc
    }, {})
  }, [clientInfo, isParticipant])

  const visualizationsParams = useMemo(
    () => ({
      clientId,
    }),
    [clientId],
  )
  useTrackVisualizationsTabSelection({
    category: CATEGORIES.clientPage,
    params: visualizationsParams,
    currentTab,
  })

  useEffect(() => {
    if (scrollTo) {
      setTimeout(() => {
        document.getElementById('amendments').scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 400)
      history.replace({
        search: queryString.stringify({
          tab: currentTab,
        }),
      })
    }
  }, [scrollTo, history, currentTab])

  useEffect(() => {
    hideBBC()
  }, [hideBBC])

  useEffect(() => {
    if (clientId && currentTab === ClientPageTabNames.Receivables) {
      getDebtorIneligibleCategories({
        id: clientId,
        workflow: WorkflowTypes.clientPage,
      })
    }
  }, [clientId, currentTab, getDebtorIneligibleCategories])

  const currentClientName = useMemo(() => {
    if (clientId === clientInfo?.id) {
      return clientInfo?.clientName
    }
  }, [clientInfo, clientId])

  const handleInventoryDistributionInitialize = useCallback(() => {
    setIsInventoryDistributionInitialized(true)
  }, [])

  return (
    <Box py={1} pr={2}>
      <Grid container spacing={2}>
        <ClientDashboardHeader
          clientId={clientId}
          clientInfo={clientInfo}
          isLoading={isLoadingClient}
          loginAsClient={loginAsClient}
          isAdminRightsRole={isAdminRightsRole}
        />

        <Grid item xs={12}>
          {clientInfo ? (
            <TabsWithRoutesMenu tabs={tabs} menu={MENU} />
          ) : (
            <Skeleton width={644} height={80} />
          )}
        </Grid>

        {currentTab === ClientPageTabNames.Overview && (
          <>
            <Grid item xs={12}>
              {clientActivityOption === ClientPageActivityOption.AccountActivity ? (
                <ClientAccountActivity setActivityOption={setClientActivityOption} />
              ) : (
                <LoanLedger setActivityOption={setClientActivityOption} />
              )}
            </Grid>

            <Grid item xs={12}>
              {!isParticipant && <ClientAmendments />}
            </Grid>

            <Grid item xs={12}>
              {!isParticipant && <ClientTermLoans />}
            </Grid>
          </>
        )}
        {currentTab === ClientPageTabNames.Financials && (
          <Grid item xs={12}>
            <ClientFinancials />
          </Grid>
        )}
        {currentTab === ClientPageTabNames.Risk && (
          <Grid item xs={12}>
            <RiskRatingVisualization />
          </Grid>
        )}
        {currentTab === ClientPageTabNames.Receivables && (
          <>
            <Grid item xs={12}>
              <BBCARSummaryTable
                refreshCount={1}
                isFilesSaving={false}
                title={'Receivables'}
                isClientPage
                clientInfo={clientInfo}
              />
            </Grid>
            <Grid item xs={12}>
              <BBCRollforwardTable refreshCount={1} type={BBC_TABS.RECEIVABLES} isClientPage />
            </Grid>
            <Grid item xs={12}>
              <Card noHeaderMargin>
                <DilutionByCustomer workflow={WorkflowTypes.clientPage} />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card noHeaderMargin>
                <DilutionOverTime workflow={WorkflowTypes.clientPage} />
              </Card>
            </Grid>
          </>
        )}
        {currentTab === ClientPageTabNames.Payable && (
          <Grid item xs={12}>
            <BBCAPSummaryTable
              refreshCount={1}
              isFilesSaving={false}
              title={'Payables'}
              isClientPage
              clientInfo={clientInfo}
            />
          </Grid>
        )}
        {currentTab === ClientPageTabNames.Payable && (
          <Grid item xs={12}>
            <BBCRollforwardTable refreshCount={1} type={BBC_TABS.PAYABLES} isClientPage />
          </Grid>
        )}
        {currentTab === ClientPageTabNames.Inventory && currentClientName && (
          <>
            <Grid item xs={12}>
              <BBCInventoryDistributionTable
                aliases={clientInfo?.inventoryIneligibilityFieldAliases}
                type={WorkflowPage.client}
                onInitialize={handleInventoryDistributionInitialize}
              />
            </Grid>
            {isInventoryDistributionInitialized && (
              <Grid item xs={12}>
                <SalesBySKUVisualizationTable
                  type={WorkflowPage.client}
                  currentId={clientInfo?.id}
                />
              </Grid>
            )}
          </>
        )}
        {currentTab === ClientPageTabNames.Inventory && !isParticipant && (
          <Grid item xs={12}>
            <InventoryHealthVisualization />
          </Grid>
        )}
        {currentTab === ClientPageTabSubNames.CapTable && (
          <Grid item xs={12}>
            <CapTableSummaryTable />
          </Grid>
        )}
        {currentTab === ClientPageTabSubNames.Background && (
          <Grid item xs={12}>
            <DueDiligenceCompanyBackgroundPage isClientPage />
          </Grid>
        )}
        {currentTab === ClientPageTabSubNames.TheTeam && (
          <Grid item xs={12}>
            <DueDiligenceTeamPage isClientPage />
          </Grid>
        )}

        {currentTab === ClientPageTabNames.BankTransactions && (
          <Grid item xs={12}>
            <Card noHeaderMargin>
              <BankTransactions
                workflow={WorkflowTypes.clientPage}
                clientName={clientInfo?.clientName}
              />
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default ClientPage
